<template>
  <div class="positon">
    <div class="quotes">
      <el-tabs v-model="type">
        <el-tab-pane :label="$t(285)" name="history"> </el-tab-pane>
        <el-tab-pane :label="$t(286)" name="cur" v-if="$route.name==='Contract'"> </el-tab-pane>
        <!-- <el-radio-group v-model="mode" size="mini">
          <el-radio v-if="!showLever" label="1" border>{{ $t("records.3") }}</el-radio>
          <el-radio v-if="!showLever && configObj.isShowTogetherBuy" label="2" border>{{ $t("records.4") }}</el-radio>
          <el-radio v-if="showLever" label="3" border>{{ $t("records.5") }}</el-radio>
          <el-radio v-if="showLever && type==='cur'" label="4" border>{{ $t("records.6") }}</el-radio>
        </el-radio-group> -->
        <el-table
          :data="formatedList"
          min-height="200"
          max-height="500"
        >
          <el-table-column
            key="createTime"
            :label="`${$t(287)}`"
            prop="createTime"
            width="160"
          >
            <template slot-scope="scope">{{ formatDateTime(scope.row.createTime||scope.row.openingTime) }}</template>
          </el-table-column>
          <el-table-column key="productName" :label="`${$t(276)}`" prop="productName">
            <template slot-scope="scope">
              <span>{{ scope.row.alias }}/{{scope.row.medium}}</span>
            </template>
          </el-table-column>
          <el-table-column key="direction" :label="`${$t(32)}`">
            <template slot-scope="scope">
              <span :style="{ color: $route.name === 'Contract' ?(scope.row.buyDirection === 1 ? riseColor : fallColor) :(scope.row.tradeType === 1 ? riseColor : fallColor) }">
                {{ $route.name === 'Contract' ? (scope.row.buyDirection === 1 ? $t(264) : $t(265)) : (scope.row.tradeType === 1 ? $t(288) : $t(289)) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column key="price" :label="`${$t(74)}`" prop="price" >
            <template slot-scope="scope">
              <span>
                {{ $route.name === 'Contract' ? scope.row.openingPrice : scope.row.price }}
              </span>
            </template>
          </el-table-column>
          <el-table-column v-if="$route.name==='Spot'" prop="costAmount" key="costAmount" :label="$t(290)">
          </el-table-column>
          <el-table-column prop="amount" :label="$t(291)" key="amount">
             <template slot-scope="scope">
              <span>
                  {{ $route.name === 'Contract' ? scope.row.buyAmount: scope.row.mediumAmount }}
                </span>
              </template>
          </el-table-column>
          <el-table-column v-if="$route.name === 'Contract' && type==='history'" key="contract" prop="contract" :label="$t(292)">
            <template slot-scope="scope">
              {{ scope.row.contractVal }}{{ unitMap[scope.row.contractUnit] }}
              </template>
          </el-table-column>
          <el-table-column v-if="$route.name === 'Contract' && type==='history'" key="closingTime" prop="closingTime" :label="$t(293)">
            <template slot-scope="scope">
                {{ formatDateTime(scope.row.closingTime) }}
              </template>
          </el-table-column>
          <el-table-column v-if="$route.name === 'Contract' && type==='history'" key="closingPrice" prop="closingPrice" :label="$t(279)">
          </el-table-column>
          <el-table-column v-if="$route.name === 'Contract' && type==='cur'" key="leftTime" :label="`${$t(299)}`" prop="remainingSeconds">
          </el-table-column>
          <el-table-column  v-if="$route.name === 'Contract' && type === 'cur'" key="profit" :label="`${$t(277)} (USDT)`" prop="profit">
            <template slot-scope="scope">
              <span :style="{color:scope.row.profit>=0 ? riseColor : fallColor}">
                {{ scope.row.profit || '--' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column  v-if="$route.name === 'Contract' && type === 'history'" key="winloseAmount" :label="`${$t(277)} (USDT)`" prop="winloseAmount">
            <template slot-scope="scope">
              <span :style="{color:scope.row.winloseAmount>=scope.row.buyAmount ? riseColor : fallColor}">
                {{ (scope.row.winloseAmount-scope.row.buyAmount).toFixed(4) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column  v-if="$route.name === 'Spot'" :label="`${$t(217)}`" prop="costAmount">
          </el-table-column>
          <template slot="empty">
            <div class="text-center">
              <img src="../../../assets/img/nodata.png" alt="" style="width:140px;height: auto;"><br>
              <span>{{ $t(255) }}</span>
            </div>
          </template>
        </el-table>
        <div v-if="showMore" class="more-box cursor" @click="loadMore">
          <span class="el-icon-caret-bottom"></span><span class="text">{{ $t(300) }}</span>
        </div>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getFutureHistory, getFutureRecords, getSpotRecords } from '@/api'
import tradingMixin from '@/mixins/tradingMixin'
import { mapState } from 'vuex'
import utils from '@/utils/misc'
export default {
  name: 'Records',
  components: {  },
  mixins: [tradingMixin],
  data() {
    return {
      data: [],
      timer: null,
      type: 'history',
      loading: false,
      lastId: 0,
      showMore: false,
      orderData: {},
      counter: 0,
      debouceGetData: utils.throttle(this.throttleFun, 2000),
      unitMap:{
        1: 'S',
        2: 'M',
        3: 'H',
        4: 'D'
      }

    }
  },
  computed: {
    hasToken() {
      return localStorage.token
    },
    configObj() {
      return window.configObj
    },
    formatedList() {
      if (this.$route.name === 'Spot' || this.type === 'history') return this.data || []
      return this.data.map(item => {
        const obj = { ...item, remainingSeconds: item.remainingSeconds || item.buySeconds }
        obj.remainingSeconds -= this.counter
        obj.remainingSeconds = obj.remainingSeconds < 0 ? 0 : obj.remainingSeconds
        if (obj.remainingSeconds !== 0) {
          obj.remainingSeconds = this.formatLeftTime(+obj.remainingSeconds)
        } else {
          obj.remainingSeconds = this.$t(273)
          this.debouceGetData(false)
          this.$store.dispatch('getUserAssets')
        }
        const curPrice = this.coinPriceMap[this.curCoinName+'USDT'].price
        obj.profit = obj.buyDirection === 1 ? (+curPrice > +obj.openingPrice ? obj.buyAmount : -obj.buyAmount) : (+curPrice < +obj.openingPrice ? obj.buyAmount : -obj.buyAmount)
        obj.profit = (+obj.profit).toFixed(4)
        return obj
      })
    }
  },
  watch: {
    type(val) {
      this.getData(false)
    },
    $route(val) {
      this.getData(false)
    },
  },
  beforeMount() {
    this.getData()
    utils.on('updateList', () => {
      this.getData(false)
    })
  },
  mounted() {},
  methods: {
    getData(shouldConcat = true) {
      if (!this.userLogined) return
      this.loading = true
      const api = this.$route.name === 'Contract' ? (this.type==='cur' ? getFutureRecords : getFutureHistory) : getSpotRecords
      // if (!shouldConcat) {
      //   this.data = []
      // }
      api({
        lastId: shouldConcat ? this.lastId : 0,
        pageSize: 20
      }).then(res => {
        if (res.orders instanceof Array) {
          this.counter = 0
          clearInterval(this.timer)
          this.timer = setInterval(() => {
            this.counter += 1
          }, 1000)
          if (shouldConcat) {
            this.data = [...this.data, ...res.orders]
          } else {
            this.data = res.orders
          }
          if (res.orders.length === 20) {
            this.lastId = res.orders[res.orders.length - 1].id
          }
          this.showMore = res.orders.length === 20
        } else {
          this.data = []
        }
      })
    },
    throttleFun() {
      this.getData(false)
      this.$store.dispatch('getUserAssets')
    },
    formatDateTime(time) {
      if (!+time) return '--'
      return utils.
      formatDate(time*1000, true)
    },
    loadMore() {
      this.getData()
    },
    update() {
      this.getData(false)
    }
  },
  beforeMounted() {
    this.getData()
  }
}
</script>

<style lang="scss">
.positon {
  margin-top: 10px;
  height: 100%;
  background: #fff;
  .el-tabs {
    padding: 0px;
  }

  .el-tabs__item {
    color: #b0b8db;
  }

  .el-table__header-wrapper {
    padding-left: 4px;
  }

  .el-table th {
    padding: 0px !important;
  }

  .el-table .cell,
  .el-table--border td:first-child .cell,
  .el-table--border th:first-child .cell {
    padding-right: 16px;
    text-align: center;
  }
  .el-table{
    .el-button{
      font-size: 12px;
    }
  }
  .el-table td.text-left .cell {
    padding-left: 20px;
    height: 26px;
  }
  .el-table__empty-block{
    min-height: 200px;
  }
  .el-tabs__item.is-active {
    color: #00d3ad;
  }
  .el-button.empty-login {
    font-size: 13px;
    text-decoration: underline;
    color: #909399;
  }

  .el-table__empty-text {
    font-size: 12px;
    line-height: 26px;
  }
  .el-radio-group{
    margin: 26px;
  }
  .more-box{
    text-align: center;
    padding: 6px;
    // background-color: #0f121f;
    cursor: pointer;
    color: #999;
    &:hover{
      color: #333;
    }
  }
}
</style>
