<template>
  <div class="product">
    <div class="quotes">
      <!-- <el-tabs v-model="type">
        <el-tab-pane :label="$t('trade.1')" name="SELF"></el-tab-pane>
        <el-tab-pane label="USDT" name="ALL"></el-tab-pane>
      </el-tabs> -->
      <div class="search">
        <el-input v-model="keywords" :placeholder="$t(198)">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <el-table
        :data="filterData"
        height="730"
        :row-class-name="rowClassMaker"
        :cell-class-name="firstColClassMaker"
        :header-cell-class-name="firstColClassMaker"
        @sort-change="sortChange"
        @row-click="rowClick"
      >
        <el-table-column
          :label="$t(253)"
          prop="coin"
          width="124"
        >
          <template slot-scope="scope">
            <img v-if="isNasdaq && scope.row.alias==='XAU'" src="@/assets/img/au.png" style="width: 20px;height:20px;margin-right: 10px;" alt="">
            <img v-else :src="coinMsg[scope.row.coin]?coinMsg[scope.row.coin].logoUri:''" alt="" style="width: 20px;height:20px;margin-right: 10px;">
            <span class="name">{{ scope.row.alias || scope.row.coin }}/{{ scope.row.medium }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t(250)"
          prop="price"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span :style="{color: scope.row.change>=0?riseColor: fallColor}">
              {{ scope.row.price }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t(254)"
          width="100"
          prop="change"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span :style="{color: scope.row.change>=0?riseColor: fallColor}">
              {{ scope.row.change>=0?'+': '' }}{{ (scope.row.change).toFixed(1) }}%
            </span>
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="text-center">
            <img src="../../../assets/img/nodata.png" alt="" style="width:140px;height: auto;"><br>
            <span>{{ $t(255) }}</span>
          </div>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getSpotList, getFutureList } from '@/api'
// import { FRESH_TIME } from '@/const'
import tradingMixin from '@/mixins/tradingMixin'
import { Message } from 'element-ui'
import utils from "@/utils/misc"

export default {
  name: 'Product',
  components: {
  },
  mixins: [tradingMixin],
  data() {
    return {
      data: [],
      timer: null,
      type: 'ALL',
      order: '',
      sortProp: '',
      curRow: 'BTC',
      keywords: '',
    }
  },
  computed: {
    isNasdaq() {
      return utils.getLs('agentIdentify') === '5dc55425-1aa9-4de7-ab6e-2156928e57d8'
    },
    hasToken() {
      return localStorage.token
    },
    filterData() {
      let arr = []
      const data = this.$route.name === 'Spot' ? this.spotList: this.futureList
      if (!this.keywords) {
        arr = data
      } else {
        arr = data.filter(item => item.coin.indexOf(this.keywords.toUpperCase()) > -1)
      }
      return arr.sort((a, b) => this.order === 'ascending' ? a[this.sortProp] - b[this.sortProp] : b[this.sortProp] - a[this.sortProp])
    }
  },
  watch: {
    type() {
      this.getTickers()
    }
  },
  beforeMount() {
    this.getTickers()
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    rowClassMaker({ row }) {
      const classStr = row.coin === this.curRow ? 'curRow' : ''
      return `${classStr}`
    },
    getTickers() {
      if (this.$route.name === 'Contract') {
        this.$store.dispatch('getFutureList')
      } else {
        this.$store.dispatch('getSpotList')
      }
    },
    sortChange({ prop, order }) {
      this.sortProp = prop
      this.order = order
      if (!this.order) return
    },
    rowClick(row) {
      this.$store.state.curCoinName = this.curRow = row.coin
    }
  }
}
</script>

<style lang="scss">
.product{
  height: 100%;
  .search{
    padding: 12px 10px 8px 10px;
    background-color: #fff;
    .el-input__inner{
      border-radius: 100px;
      height: 36px;
      line-height: 36px;
    }
    .el-input__icon{
      line-height: 36px;
    }
  }
  .quotes{
    .el-icon-star-on{
      color: #61688a;
      font-size: 20px;
      margin-right: 2px;
      transform: translateY(-2px);
      &.light{
        color: #00d3ad;
      }
    }
    .name{
      color: #707a8a;
      vertical-align: text-bottom;
    }
  }
  .el-tabs__item{
    color: #b0b8db;
  }
  .el-table__header-wrapper{
    padding-left: 4px;
  }
  .el-table .cell, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell{
    padding-right: 8px;
  }
  .el-table td .cell, .el-table--border td:first-child .cell{
    padding-right: 8px;
    height: 23px;
  }
  .el-table th{
    padding:0px !important;
  }
  .el-table tr.curRow{
    background-color: #f5f5f5;
  }
  .el-tabs__item.is-active {
      color: #00d3ad;
  }
  .el-button.empty-login{
    font-size: 13px;
    text-decoration: underline;
    color: #909399;
  }
  .el-table__empty-text{
    font-size: 12px;
    line-height: 20px
  }
}
</style>
