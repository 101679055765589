<template>
  <div class="trade-layout bg-primary1">
    <div></div>
    <div class="box-top">
      <div class="flex">
        <div class="l">
          <Product></Product>
        </div>
        <div class="m">
          <div class="kline">
            <Kline :action="action" :realTimeAction="realTimeAction"></Kline>
          </div>
          <div class="trading flex align-center">
            <slot name="trading"></slot>
          </div>
        </div>
        <div class="r">
          <Market></Market>
        </div>
      </div>
    </div>
    <div class="box-bottom">
      <Records></Records>
    </div>
  </div>
</template>

<script>
import Product from './Product'
import Market from './Market'
import Kline from './Kline'
import Records from './Records'
export default {
  name: 'TradingLayout',
  components: {
    Product,
    Market,
    Records,
    Kline
  },
  props: {
     action: {
      type: Number,
      default: 10002
    },
    realTimeAction: {
      type: Number,
      default: 10004
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.trade-layout{
  .trading{
    height: 320px;
    margin-top: 6px;
    color: #333;
    background-color: #fff;
    height: 348px;
  }
  .l{
    width: 320px;
    margin-right: 6px;
  }
  .r{
    width: 330px;
    margin-left: 6px;
  }
  .l,.m,.r{
    color:#b0b8db;
    *{
      box-sizing: border-box;
    }
    height: 780px;
  }
  .m{
    flex: 1;
    .kline{
      height: auto;
    }
  }
  .box-top{
    margin-top: 6px;
    .flex:not(.wrap){
      flex-wrap: nowrap;
    }
  }
  .el-tabs__item{
    height: 30px;
    line-height: 30px;
    font-size: 13px;
  }
  .el-table td, .el-table th.is-leaf {
    font-size: 12px;
  }
  .el-table thead{
    color:#61688a;
    font-size: 12px;
    font-weight: normal;
  }
  .el-table td, .el-table th{
    padding: 6px 0;
    color: #61688a;
  }
  .el-table td, .el-table th.is-leaf{
    border-bottom: none;
    cursor: pointer;
  }
  .el-table .sort-caret.ascending{
    border-bottom-color: #61688a;
  }
  .el-table .sort-caret.descending{
    border-top-color: #61688a;
  }
  .el-table .ascending .sort-caret.ascending{
    border-bottom-color: $primaryColor;
  }
  .el-table .descending .sort-caret.descending{
    border-top-color: $primaryColor;
  }
  .spliter{
    &:after{
      content: '';
      display: block;
      width: 1px;
      height: 240px;
      border-left: 1px dashed $borderColor;
      margin: 0px 10px;
    }
  }
  .box-bottom{
    margin-bottom: 8px;
  }
}
</style>
