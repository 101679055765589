<template>
  <div class="kline">
    <div class="coin-msg flex align-center">
      <div class="coin-name">{{ futureMap[curCoinName+'USDT'] ? futureMap[curCoinName+'USDT'].alias || curCoinName : curCoinName }}/USDT</div>
      <div class="coin-price fs18" :style="{color:coinPriceMap[curCoinName+'USDT'].change>=0?riseColor: fallColor}">
        {{ coinPriceMap[curCoinName+'USDT'].price }}
        <div class="fs12 color666">≈{{  (curRate * coinPriceMap[curCoinName+'USDT'].price).toFixed(2) }} {{ userCurrency }}</div>
      </div>
      <div class="item">
        <div class="title">{{ $t(254) }}</div>
        <div class="value" :style="{color: coinPriceMap[curCoinName+'USDT'].change>=0?riseColor: fallColor}">
              {{ coinPriceMap[curCoinName+'USDT'].change>=0?'+': '' }}{{ coinPriceMap[curCoinName+'USDT'].change }}%</div>
      </div>
      <div class="item">
        <div class="title">{{ $t(257) }}</div>
        <div class="value" >{{ coinPriceMap[curCoinName+'USDT'].high }}</div>
      </div>
      <div class="item">
        <div class="title">{{ $t(258) }}</div>
        <div class="value" >{{ coinPriceMap[curCoinName+'USDT'].low }}</div>
      </div>
      <div class="item">
        <div class="title">{{ $t(259) }}</div>
        <div class="value" >{{ coinPriceMap[curCoinName+'USDT'].volume }}</div>
      </div>
    </div>
    <div class="switch-chart flex">
      <div class="item cursor" v-for="item in chartOptions"
        :key="item.value" @click="switchChart(item)"
        :class="{active: curPeriod===item.value}">{{ item.label }}</div>
    </div>
    <div class="container">
      <div class="candle" id="candle">
      </div>
      <p class="legend" v-if="curPeriod !== 'time'">
        <span>{{ $t(260) }}: {{ legendObj.open }} </span>
        <span>{{ $t(257) }}: {{ legendObj.high }} </span>
        <span>{{ $t(258) }}: {{ legendObj.low }} </span>
        <span>{{ $t(261) }}: {{ legendObj.close }} </span>
        <span>{{ $t(262) }}: {{ legendObj.volume }}</span>
        <br>
        <span style="color: rgba(189, 165, 79, 1);">MA5: {{ legendObj.ma5 }} </span>
        <span style="color: rgba(24, 131, 222, 1);">MA5: {{ legendObj.ma10 }} </span>
        <span style="color: rgba(145, 32, 138, 1);">MA5: {{ legendObj.ma30 }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import tradingMixin from '@/mixins/tradingMixin'
import utils from '@/utils/misc'
import { createChart, CrosshairMode } from 'lightweight-charts';
import EventMap from '@/ws/EventMap.js'
// import {Message} from 'element-ui'
export default {
  name: 'Kline',
  components: {
  },
  mixins: [tradingMixin],
  props: {
    action: {
      type: Number,
      default: 10002
    },
    realTimeAction: {
      type: Number,
      default: 10004
    },
  },
  data() {
    return {
      // period 说明
      // "1m" - 1分钟,
      // "5m" - 5分钟,
      // "15m" - 15分钟,
      // "30m" - 30分钟,
      // "1h" - 1小时,
      // "4h" - 4小时,
      // "1d" - 天,
      // "1w" - 周,
      // "1M" - 月

      curPeriod: '1m',
      chart: null,
      candleSeries: null,
      areaSeries: null,
      volumeSeries: null,
      chartWidth: 800,
      chartHeight: 360,
      ma5: null,
      ma10: null,
      ma30: null,
      legendObj: {
        open: '',
        hight: '',
        low: '',
        close: '',
        volume: '',
        ma5: '',
        ma10: '',
        ma30: ''
      },
      cacheData: []
    }
  },
  computed: {
    hasToken() {
      return localStorage.token
    },
    coinMsg() {
      return this.$store.state.coinMsg
    },
    isShowRealtime() {
      return window.configObj.isShowRealtime === '1'
    },
    chartOptions() {
      let arr = [
        { label: 'Time', value: 'time' },
        { label: '1m', value: '1m' },
        { label: '5m', value: '5m' },
        { label: '15m', value: '15m' },
        { label: '30m', value: '30m' },
        { label: '1H', value: '1h' },
        { label: '6H', value: '6h' },
        { label: '1D', value: '1d' },
        { label: '1W', value: '1w' },
        { label: '1M', value: '1M' },
      ]
      if (!this.isShowRealtime) {
        arr.shift()
      }
      return arr
    },
  },
  watch: {
    curPeriod(value, oldValue) {
      this.reloadChart()
      if (value === 'time') {
        this.sendSubInfo({
          action: this.realTimeAction,
          data: {
            symbol: `${this.curCoinName}USDT`,
            period: this.curPeriod,
            platform: utils.getLs('agentIdentify'),
            limit: 200
          }
        })
        this.initAreaChart()
      } else {
        if (oldValue === 'time') {
          this.candleSeries = this.chart.addCandlestickSeries();
        }
        this.sendSubInfo({
          action: this.action,
          data: {
            symbol: `${this.curCoinName}USDT`,
            period: this.curPeriod,
            platform: utils.getLs('agentIdentify'),
          }
        })
      }
    },
    curCoinName() {
      this.reloadChart()
      this.initSub()
      if (this.curPeriod === 'time') {
        this.initAreaChart()
      }
    }
  },
  beforeMount() {
    if (this.$route.name==='Contract' && this.isShowRealtime) {
      this.curPeriod = 'time'
    }
    this.initEvents()
    this.initSub()
    console.log(this.futureMap, this.curCoinName)
  },
  mounted() {
    this.initChart()
    utils.on('wsopen', this.initSub)
  },
  beforeDestroy() {
  },
  methods: {
    initSub() {
      if (this.curPeriod === 'time') {
        this.sendSubInfo({
          action: this.realTimeAction,
          data: {
            symbol: `${this.curCoinName}USDT`,
            period: this.curPeriod,
            platform: utils.getLs('agentIdentify'),
            limit: 200
          }
        })
      } else {
        this.sendSubInfo({
          action: this.action,
          data: {
            symbol: `${this.curCoinName}USDT`,
            period: this.curPeriod,
            platform: utils.getLs('agentIdentify'),
          }
        })
      }
    },
    initChart() {
      const dom = this.$el.querySelector('#candle')
      this.chartWidth = dom.offsetWidth
      this.chart = createChart(this.$el.querySelector('#candle'), {
        width: this.chartWidth,
        height: this.chartHeight,
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        localization: {
          locale: utils.getLs("locale") || window.configObj.defaultLang
        }
      });
      this.chart.applyOptions({
        localization: {
          dateFormat: 'yyyy/MM/dd',
        },
        grid: {
          vertLines: {
            color: "rgba(70, 130, 180, 0.5)",
            style: 1,
            visible: false
          },
          horzLines: {
            color: '#e6ecf2',
            style: 1,
            visible: true
          }
        },
        layout: {
          textColor: "#696969",
          fontSize: 12
        },
        priceScale: {
          borderVisible: false
        },
        timeScale: {
          timeVisible: true,
          borderColor: '#D1D4DC',
        }
      })
      const colorMode = utils.getLs('colorMode')
      const precision = this.$store.state.spotList[`${this.curCoinName}USDT`].precision
      this.candleSeries = this.chart.addCandlestickSeries({
        upColor: this.riseColor,
        downColor: this.fallColor,
        borderUpColor: this.riseColor,
        borderDownColor: this.fallColor,
        wickUpColor: this.riseColor,
        wickDownColor: this.fallColor,
        priceFormat: {
          type: 'custom',
          formatter: function(price){
            return price.toFixed(precision)
          }
        }
      });
      this.chart.subscribeCrosshairMove((param) => {
        this.handleMove(param)
      })
			this.chart.subscribeClick((param) => {
        this.handleMove(param)
      })
      window.onresize = null
      window.onresize = utils.debouce(() => {
        this.chartWidth = document.body.offsetWidth-670
        this.chart.resize(this.chartWidth, this.chartHeight)
      })
    },
    reloadChart() {
      this.chart.remove()
      this.chart = null
      this.initChart()
    },
    switchChart(obj) {
      this.curPeriod = obj.value
    },
    initAreaChart() {
      if (this.candleSeries){
        this.chart.removeSeries(this.candleSeries)
      }
      const precision = this.$store.state.spotList[`${this.curCoinName}USDT`].precision
      this.areaSeries = this.chart.addAreaSeries({
        lineColor: '#12b886',
        topColor: '#12b886',
        lineWidth: 1,
        priceFormat: {
          type: 'custom',
          formatter: function(price){
            return price.toFixed(precision)
          }
        }
      });
    },
    calculateMA(data, count) {
      const avg = function(data) {
        let sum = 0;
        for (let i = 0; i < data.length; i++) {
          sum += data[i].close;
        }
        return sum / data.length;
      };
      const result = [];
      for (let i = count - 1, len = data.length; i < len; i++) {
        let val = avg(data.slice(i - count + 1, i));
        result.push({
          time: data[i].time,
          value: val
        });
      }
      return result;
    },
    createMA(data) {
      if (this.chart) {
        const smaData5 = this.calculateMA(data, 5);
        const smaData10 = this.calculateMA(data, 10);
        const smaData30 = this.calculateMA(data, 30);
        this.ma5 = this.chart.addLineSeries({
          color: 'rgba(189, 165, 79, 1)',
          lineWidth: 1,
          priceLineVisible: false,
          lastValueVisible: false
        });
        this.ma10 = this.chart.addLineSeries({
          color: 'rgba(24, 131, 222, 1)',
          lineWidth: 1,
          priceLineVisible: false,
          lastValueVisible: false
        });
        this.ma30 = this.chart.addLineSeries({
          color: 'rgba(145, 32, 138, 1)',
          lineWidth: 1,
          priceLineVisible: false,
          lastValueVisible: false
        });
        this.ma5.setData(smaData5);
        this.ma10.setData(smaData10);
        this.ma30.setData(smaData30);
        this.setLegendText(smaData5[smaData5.length - 1].value, smaData10[smaData10.length - 1].value,
          smaData30[smaData30.length - 1].value);
      }
    },
    handleMove(param) {
      this.setLegendText(param.seriesPrices.get(this.ma5), param.seriesPrices.get(this.ma10), param
        .seriesPrices.get(this.ma30),param.seriesPrices.get(this.candleSeries),param.seriesPrices.get(this.volumeSeries));
    },
    updateVolumes(data) {
      let vData = {
        time: data.time,
        value: data.volume,
        // color: data.close > data.open ? 'rgba(0, 150, 136, 0.5)' : 'rgba(255,82,82, 0.5)'
        color: data.close > data.open ? this.riseColor : this.fallColor
      }
      this.volumeSeries.update(vData)
    },
    createVolumes(data) {
      this.volumeSeries = this.chart.addHistogramSeries({
        color: '#26a69a',
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: '',
        scaleMargins: {
          top: 0.9,
          bottom: 0,
        },
        priceLineVisible: false,
        lastValueVisible: false
      });
      let vData = []
      data.map(item => {
        vData.push({
          time: item.time,
          value: item.volume,
          color: item.close > item.open ? this.riseColor : this.fallColor
        })
      })
      this.volumeSeries.setData(vData)
			},
    setLegendText(price1, price2, price3, candle, vol) {
      if (price1 !== undefined) {
        this.legendObj.ma5 = (Math.round(price1 * 100) / 100).toFixed(4);
      }
      if (price2 !== undefined) {
        this.legendObj.ma10 = (Math.round(price2 * 100) / 100).toFixed(4);
      }
      if (price3 !== undefined) {
        this.legendObj.ma30 = (Math.round(price3 * 100) / 100).toFixed(4);
      }
      if (vol) {
        this.legendObj.volume = vol
      }
      if (candle) {
        this.legendObj = {...this.legendObj, ...candle}
      }
    },
    sendSubInfo(data) {
      if (this.$ws.ws.m_state === 1) {
        this.$ws.send(data)
      } else {
        setTimeout(() => {
          this.sendSubInfo(data)
        }, 100)
      }
    },
    initEvents() {
      // 服务端推送分时图数据
      utils.off(EventMap[this.realTimeAction])
      utils.on(EventMap[this.realTimeAction], data => {
        if (this.curPeriod !== 'time') return
        let ticks = data.data.lists
        let arrayData = [];
        const timeZone = new Date().getTimezoneOffset()
        ticks.map((item) => {
          arrayData.push({
            time: parseInt((item.time*1000-timeZone*60*1000) / 1000),
            value: item.price
          });
        });
        if (arrayData.length > 1) {
          this.areaSeries.setData(arrayData)
        } else {
          this.areaSeries.update(arrayData[0])
        }
      });
      // 服务端推送K线数据
      utils.off(EventMap[this.action])
      utils.on(EventMap[this.action], data => {
        if (this.curPeriod === 'time') return
        let ticks = data.data.klineInfos
        let arrayData = [];
        const timeZone = new Date().getTimezoneOffset()
        ticks.map((item) => {
          arrayData.push({
            time: parseInt((item.startTime-timeZone*60*1000) / 1000),
            close: item.close,
            open: item.open,
            high: item.high,
            low: item.low,
            volume: item.volume,
          });
        });
        if (arrayData.length > 1) {
          this.candleSeries.setData(arrayData)
          this.createVolumes(arrayData)
          this.createMA(arrayData)
          this.cacheData = arrayData
          this.setLegendText(this.legendObj.ma5,this.legendObj.ma10,this.legendObj.ma30, arrayData[arrayData.length-1], arrayData[arrayData.length-1].volume)
        } else {
          this.candleSeries.update(arrayData[0])
          this.updateVolumes(arrayData[0])
          if (this.cacheData[this.cacheData.length - 1].time < arrayData[0].time) {
            this.cacheData.push(arrayData[0])
            this.createMA(this.cacheData)
          }
          this.setLegendText(this.legendObj.ma5,this.legendObj.ma10,this.legendObj.ma30, arrayData[0], arrayData[0].volume)
        }
      });
    }
  }
}
</script>

<style lang="scss">
.kline{
  background: #fff;
  .container{
    width: 100%;
    position: relative;
    .candle{
      width: 100%;
    }
    .legend{
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 100;
      font-size: 12px;
    }
  }
  .coin-msg{
    padding: 6px 10px;
    .coin-name{
      font-size: 18px;
      color: #333;
      margin-right: 20px;
    }
    .item{
      font-size: 12px;
      margin-left: 20px;
      color: #333;
      .title{
        color: #999;
        margin-bottom: 4px;
      }
    }
  }
  .switch-chart{
    padding: 0px 6px;
    .item{
      padding: 0px 8px;
      height: 24px;
      line-height: 24px;
      &.active{
        color: $primaryColor;
        background: #e8eaf1;
      }
    }
  }
}
</style>
